.SphereNameForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 400px;
}

.SphereNameForm-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SphereNameForm-or {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

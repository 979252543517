.NodeTableContainer {
    display: flex;
    margin-top: 16px;
    width: 100%;
    max-height: calc(100vh - 100px - 3 * var(--appMargin));
}

.NodeTable {
    width: 100%;
    margin: 0px;
}

.SwitchContainer {
    height: 40px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NewNodeForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 600px;
}

.NewNodeForm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NewNodeForm-content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 12px;
}

.NewNodeForm-footer {
    display: flex;
    justify-content: end;
    margin-top: 12px;
}

.NewNodeForm-editing-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.SphereRenameForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 400px;
}

.SphereRenameForm-footer {
    display: flex;
    justify-content: end;
    margin-top: 12px;
}

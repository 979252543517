.Start {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.Start-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.Start-logo {
    height: 20vmin;
}
.Start-short-desc {
    width: 50%;
    margin-top: 48px;
}
.Start-long-desc {
    width: 40%;
    margin-top: 24px;
    margin-bottom: 24px;
}
.Start-continue-btn {
    min-width: 200px;
}
.Start-link-btn {
    min-width: 80px;
}

.BalanceView {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
    min-height: calc(100vh - 164px - 3 * var(--appMargin));
}


.BalanceView .StatsContainer {
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: rgba(95, 158, 160, 0.1);
    border-radius: 10px;
}

.BalanceView .StatsContainer .InfoContainer {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.VerticalLine {
    display: flex;
    width: 2px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.249);
}

.BalanceView .StatsContainer .ProgressContainer {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}



.BalanceTableContainer {
    display: flex;
    margin-top: 16px;
    width: 100%;
    max-height: calc(100vh - 354px - 3 * var(--appMargin));
}

.BalanceTableContainer .Table {
    width: 100%;
}

.BalanceTableContainer .Table .LineContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 44px;
}

.BalanceTableContainer .Table .LineContainer .LineInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.BalanceTableContainer .Table .LineContainer .Text {
    margin-bottom: 4px;
}

.EmptyTable {
    display: flex;
    max-width: 400px;
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 24px;
    margin-right: 24px;
    text-align: center;
}


.LinkTable-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.LinkTable-header-add {
    margin-top: 8px;
    margin-bottom: 8px;
}

.LinkTable-table {
    margin-top: 8px;
}

.LinkTable-empty {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 80%;
}

.MainFooter {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: #e0e5ee; */
}

.MainFooter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 16px;
    margin-right: 16px;
}

.Renderer-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.Renderer {
    margin-top: 16px;
    width: 100%;
    max-height: calc(100vh - 100px - 3 * var(--appMargin));
    overflow-y: scroll;
    overflow-x: scroll;
    background-color: #ffffff;
}

.Renderer-empty {
    display: flex;
    justify-content: center;
}

.Renderer-svg {
    display: flex;
    background-color: #ffffff;
}

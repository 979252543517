.CountingView {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    width: 100%;
    max-height: calc(100vh - 100px - 3 * var(--appMargin));
    background-color: white;
}

.CountingView .ChoiceGroup {
    margin-top: 8px;
    background-color: white;
    height: fit-content;
}

.CountingView .ChoiceGroup-Label {
    width: 160px;
}
.ProductCard {
    width: 260px;
}
.ProductCard-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    white-space: wrap;
}
.ProductCard-header {
    text-align: center;
}
.ProductCard-price {
    text-align: center;
}
.ProductCard-month-price {
    text-align: center;
}


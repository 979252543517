.ChargeAddForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 400px;
}

.ChargeAddForm-content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
}

.ChargeAddForm-footer {
    display: flex;
    justify-content: end;
    margin-top: 12px;
}

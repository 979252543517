.TariffPage {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.TariffPage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}
.TariffPage-card-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    overflow: auto;
    white-space: nowrap;
    padding: 16px;
}
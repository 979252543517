.RegisterForm {
    width: 600px;
    padding: 16px;
}

.RegisterForm-container {
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
}

.RegisterForm-container-error {
    min-height: 380px;
    min-width: 380px;
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
}

.RegisterForm-page {
    min-height: 180px;
    padding-bottom: 24px;
}

.RegisterForm-nameField {
    padding-top: 16px;
}

.RegisterForm-caption {
    padding-top: 16px;
}

.RegisterForm-note {
    padding-top: 4px;
}

.RegisterForm-steps {
    padding-top: 16px;
}
.PageHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.PageHeader-container {
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.sphere-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
}

.sphere-header {
    display: flex;
    width: 100%;
    height: 40px;
}

.sphere-header .button-container {
    display: inline-flex;
    align-items: end;
}

.sphere-header-button {
    margin-left: 8px;
}

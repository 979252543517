.TaskPriorityTableContainer {
    display: flex;
    margin-top: 16px;
    width: 100%;
    max-height: calc(100vh - 164px - 3 * var(--appMargin));
}

.TaskPriorityTableContainer .Table {
    width: 100%;
}

.SwitchContainer {
    height: 40px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

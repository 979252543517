.Content {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 16px;
    margin-right: 16px;
}

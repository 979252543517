.EnterForm {
    width: 600px;
    padding: 16px;
}

.EnterForm-container {
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
}

.EnterForm-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.EnterForm-container-error {
    min-height: 380px;
    min-width: 380px;
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
}

.EnterForm-nameField {
    padding-top: 16px;
}

.EnterForm-caption {
    padding-top: 16px;
}

.EnterForm-note {
    padding-top: 4px;
}
.IterationAddForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 600px;
}

.IterationAddForm-content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
}

.IterationAddForm-footer {
    display: flex;
    justify-content: end;
    margin-top: 12px;
}

.IterationAddForm-content .Description {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
}
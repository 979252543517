.ProductForm {
    min-width: 600px;
    padding: 16px;
}

.ProductForm-container {
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.ProductForm-card-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    overflow: auto;
    white-space: nowrap;
    padding: 16px;
}
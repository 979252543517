.SphereImportForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 400px;
}

.SphereImportForm-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SphereImportForm-content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
}

.SphereImportForm-footer {
    display: flex;
    justify-content: end;
    margin-top: 12px;
}


.Table-empty-container {
    display: grid;
	align-items: center;
    min-height: 120px;
}

.Table-empty {
    margin-left: 20px;
    margin-right: 20px;
}

.Table-loader-container {
    flex-direction: column;
    position: relative;
    min-height: 120px;
}

.Table-loader {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

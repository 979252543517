:root {
    --appMargin: 16px;
}
.App {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f0f5ff;
}
.App-content {
    display: flex;
    flex-direction: column;
    margin: var(--appMargin);
    min-height: calc(100vh - 2 * var(--appMargin));
    width: calc(100% - 2 * var(--appMargin));
    /* background-color: #6593ee; */
}

.EditLinkForm {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.EditLinkFormContent {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: fit-content;
    gap: 20px;
}

.EditLinkFormContentButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}
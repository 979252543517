.AlertForm {
    min-width: 200px;
    max-width: 600px;
    padding: 16px;
}

.AlertForm-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.AlertForm-card-container {
    white-space: nowrap;
}
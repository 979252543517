.ChargeTable {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
    max-height: calc(100vh - 100px - 3 * var(--appMargin));
}
.ChargeTable .Table {
    width: 100%;
}
